var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { services } from './../index';
import { convertFieldsToType } from './converters';
import { formatData, mapRelations, responseHandler } from './helpers';
import mappers from './mappers';
export function getData(parameters) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        const [error, response] = yield responseHandler(fetch(`api/data/get/${parameters.entityTypeName}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(mappers.getDataRequest(parameters)),
        }));
        if (error) {
            services.toast.error('Произошла ошибка при загрузке данных');
            return [error, undefined, {}];
        }
        let data = response.entities.map(x => convertFieldsToType(x, parameters.converters));
        data = mapRelations(data, (_a = response.relations) !== null && _a !== void 0 ? _a : [], (_b = parameters.relations) !== null && _b !== void 0 ? _b : [], (_c = parameters.converters) !== null && _c !== void 0 ? _c : []);
        data = formatData(data, parameters.formatters);
        return [undefined, data, { totalResults: response === null || response === void 0 ? void 0 : response.totalResults }];
    });
}
export function saveData(parameters) {
    return __awaiter(this, void 0, void 0, function* () {
        const [error, response] = yield responseHandler(fetch(`/api/data/save/${parameters.entityTypeName}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(mappers.saveDataRequest(parameters)),
        }));
        if (error) {
            services.toast.error('Произошла ошибка при сохранении данных');
            return [error, undefined];
        }
        return [undefined, response];
    });
}
export function deleteData(parameters) {
    return __awaiter(this, void 0, void 0, function* () {
        const [error] = yield responseHandler(fetch(`/api/data/delete/${parameters.entityTypeName}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(mappers.deleteDataRequest(parameters)),
        }));
        if (error) {
            services.toast.error('Произошла ошибка при сохранении данных');
            return [error];
        }
        return [undefined];
    });
}
