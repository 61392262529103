import { useMaskInput } from './use-mask-input';
import { isNotNullOrUndefined } from '@/utils/guards';
import tokens from '@/utils/mask/tokens';
import { computed, defineComponent, ref, watch, } from 'vue';
import { numberTypes, useNumberInput } from './use-number-input';
import { excludeVue2Attributes, mergeClass } from '@/utils/render';
import { validateRegExp } from './use-validate';
import { createUniqueId } from '@/utils/create-unique-id';
const types = ['text', 'password', 'mask', ...numberTypes];
const InputComponent = defineComponent({
    inheritAttrs: false,
    emits: ['update:valid', 'update:modelValue', 'focus', 'blur', 'input', 'keypress', 'click', 'paste'],
    props: {
        modelValue: { type: [String, Number] },
        id: { type: String, default: () => 'input-' + createUniqueId() },
        label: { type: String },
        detail: { type: String },
        counter: { type: String },
        type: {
            type: String,
            default: 'text',
            validator: (x) => types.includes(x),
        },
        validation: {
            type: Function,
            default: () => true,
        },
        bordered: { type: Boolean },
        readonly: { type: Boolean },
        disabled: { type: Boolean },
        required: { type: Boolean },
        preventPaste: { type: Boolean },
        trim: { type: Boolean },
        buttons: { type: Array, default: () => [] },
        min: { type: [Number, String], default: -9999999999 },
        max: { type: [Number, String], default: 9999999999 },
        decimalPlaces: { type: [Number, String], default: -1 },
        numberFormatOptions: { type: Object, default: () => ({}) },
        measureStr: { type: String, default: '' },
        regExp: { type: RegExp, default: null },
        mask: { type: String },
        tokens: {
            type: Object,
            default: tokens,
        },
    },
    setup(props, { attrs, emit }) {
        const input = ref();
        const detailMessage = ref(null);
        const labelClasses = ref({
            'v-label--active': false,
        });
        const mutableRootClasses = ref({
            'v-input--focused': false,
            'secondary--text': false,
            'v-input--error': false,
        });
        const noLabel = computed(() => !props.label);
        const labelValue = computed(() => {
            var _a, _b;
            if (props.label && props.counter) {
                return props.label + `(${String((_a = props.modelValue) !== null && _a !== void 0 ? _a : '').length}/${props.counter})`;
            }
            if (props.label)
                return props.label;
            if (props.counter) {
                return `${String((_b = props.modelValue) !== null && _b !== void 0 ? _b : '').length}/${props.counter}`;
            }
            return '';
        });
        const rootClasses = computed(() => ({
            'v-input--focused': mutableRootClasses.value['v-input--focused'],
            'v-input--error': mutableRootClasses.value['v-input--error'],
            'v-input--iv-readonly': Boolean(props.readonly),
            'v-input--iv-disabled': Boolean(props.disabled),
            'v-input--no-label': noLabel.value,
            'v-input--required': Boolean(props.required),
            'v-input--bordered': Boolean(props.bordered),
            'v-input--placeholder': Boolean(attrs.placeholder),
            ['v-input--' + props.type]: true,
            'v-input--bordered-number': Boolean(numberTypes.join(', ').includes(props.type) && props.bordered),
            'v-input--bordered-mask': Boolean(props.type === 'mask' && props.bordered),
        }));
        const { attributes: a, valueWrapper: vw } = props.type === 'mask'
            ? useMaskInput(props, { emit }, mutableRootClasses, validate)
            : useNumberInput(props, { attrs, emit }, mutableRootClasses, validate);
        const overloadAttributes = a;
        const overloadValueWrapper = vw;
        const update = (value, event) => {
            if (props.trim && typeof value === 'string') {
                emit('update:modelValue', value.trim(), event);
            }
            else
                emit('update:modelValue', value, event);
        };
        const valueWrapper = overloadValueWrapper !== null && overloadValueWrapper !== void 0 ? overloadValueWrapper : computed({
            get() {
                return props.modelValue;
            },
            set(value) {
                update(value);
            },
        });
        const attributes = computed(() => Object.assign(excludeVue2Attributes(attrs), Object.assign({ onInput: (event) => {
                emit('input', event.target.value, event);
            }, onChange: (event) => {
                const value = event.target.value;
                if (props.validation(value) === true)
                    update(value, event);
            }, onFocus: () => {
                mutableRootClasses.value['v-input--focused'] = true;
                emit('focus');
            }, onBlur: () => {
                mutableRootClasses.value['v-input--focused'] = false;
                emit('blur');
            }, onKeypress: (event) => {
                validateRegExp(props.regExp, event.target.value + event.key)
                    ? emit('keypress', event)
                    : event.preventDefault();
            }, onClick: (event) => {
                emit('click', event);
            }, onPaste: (event) => {
                if (props.preventPaste)
                    event.preventDefault();
                else
                    emit('paste', event);
            } }, overloadAttributes.value)));
        let previousValid = true;
        function updateValid(validation) {
            if (previousValid === validation)
                return;
            previousValid = validation;
            emit('update:valid', validation === true);
        }
        function validate(value) {
            let validation = props.validation(value);
            if (props.readonly || props.disabled)
                validation = true;
            mutableRootClasses.value['v-input--error'] = validation !== true;
            if (detailMessage.value !== validation)
                updateValid(validation);
            detailMessage.value = validation === true ? null : validation;
        }
        validate(props.modelValue);
        function focus() {
            if (props.disabled)
                return;
            mutableRootClasses.value['v-input--focused'] = true;
            labelClasses.value['v-label--active'] = true;
        }
        function unfocus() {
            if (document.activeElement === input.value)
                return;
            mutableRootClasses.value['v-input--focused'] = false;
            if (!isNotNullOrUndefined(props.modelValue) || props.modelValue.toString() === '')
                labelClasses.value['v-label--active'] = false;
            validate(props.modelValue);
        }
        watch(() => props.modelValue, () => (labelClasses.value['v-label--active'] =
            isNotNullOrUndefined(props.modelValue) && props.modelValue.toString() !== ''), { immediate: true });
        return {
            noLabel,
            labelValue,
            detailMessage,
            labelClasses,
            rootClasses,
            attributes,
            input,
            focus,
            unfocus,
            mergeClass,
            valueWrapper,
        };
    },
});
export default InputComponent;
