var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { services } from './../services/index';
import { createRouter, createWebHistory } from 'vue-router';
import { nextTick } from 'vue';
import ListViewerComponent from '@/components/v-list-viewer.vue';
import manifest from '@/modules/manifest.json';
const moduleNavbarRoutes = manifest
    .filter(x => x.navbar)
    .map(x => ({
    path: `/${x.moduleName.toLocaleLowerCase()}`,
    name: `${x.moduleName.toLocaleLowerCase()}_viewer`,
    component: ListViewerComponent,
    props: {
        entityName: x.entityName,
        module: x.moduleName,
    },
    meta: {
        securedAction: x.securedActions.view,
        navbar: x.navbar,
    },
}));
const moduleDetailRoutes = manifest
    .filter(x => x.navbar)
    .map(x => ({
    path: `/${x.moduleName.toLocaleLowerCase()}/:id`,
    name: `${x.moduleName.toLocaleLowerCase()}`,
    component: () => import(`@/modules/${x.moduleName}/index.ts`),
    props: route => ({ entityId: Number(route.params.id) }),
    meta: {
        securedAction: x.securedActions.view,
    },
}));
const loginRoute = {
    path: '/login',
    name: 'login',
    component: () => import('@/components/login/v-login.vue'),
    meta: {
        securedAction: null,
    },
};
const noAccess = {
    path: '/noAccess',
    name: 'noAccess',
    component: () => import('@/components/v-no-access.vue'),
    meta: {
        securedAction: null,
    },
};
const startPage = {
    path: '/startPage',
    name: 'startPage',
    component: () => import('@/components/v-start-page.vue'),
    meta: {
        securedAction: null,
    },
};
const routes = [...moduleNavbarRoutes, ...moduleDetailRoutes, loginRoute, noAccess, startPage];
const componentsRoute = {
    path: '/components',
    name: 'components',
    component: () => import('@/components/base/components-overview/components-overview.vue'),
};
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: /*process.env.NODE_ENV === 'development' ? */ [...routes, componentsRoute], //: routes,
});
router.beforeEach((to, from, next) => __awaiter(void 0, void 0, void 0, function* () {
    yield services.store.user.promise;
    if (to.name === 'login')
        next();
    if (services.store.user.user === null)
        return next('/login');
    if (to.meta.securedAction !== null && !services.store.user.hasAccess(to.meta.securedAction))
        return next({
            name: 'noAccess',
        });
    return next();
}));
router.afterEach(() => {
    nextTick(() => {
        document.title = 'CRM';
    });
});
export default router;
