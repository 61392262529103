var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { services } from './../services/index';
import { api } from '@/services/api';
import { defineStore } from 'pinia';
export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        user: null,
        loading: true,
        promise: null,
    }),
    actions: {
        setUser(userInfo) {
            this.user = userInfo;
        },
        loadUser() {
            return __awaiter(this, void 0, void 0, function* () {
                this.loading = true;
                this.promise = api.web.get('api/security/getCurrentUser');
                const [error, user = null] = yield this.promise;
                if (error)
                    services.toast.error(error);
                this.user = user;
                this.loading = false;
                return this.user !== null;
            });
        },
        logout() {
            return __awaiter(this, void 0, void 0, function* () {
                const [error] = yield api.web.post('api/security/Logout');
                if (error)
                    services.toast.error(error);
                return !error;
            });
        },
        login(login, password) {
            return __awaiter(this, void 0, void 0, function* () {
                this.loading = true;
                const [error] = yield api.web.post('api/security/login', {
                    userName: login,
                    password: password,
                });
                if (error) {
                    services.toast.error(error);
                    this.loading = false;
                    return false;
                }
                yield this.loadUser();
                this.loading = false;
                return true;
            });
        },
        hasAccess(action) {
            var _a;
            if (!this.user)
                return false;
            if (this.user.user.key === 1)
                return true;
            return (_a = this.user.securedActions.includes(action.toLowerCase())) !== null && _a !== void 0 ? _a : false;
        },
    },
});
