import { omit } from 'lodash';
import { isTruthy } from './guards';
export const excludeVue2Attributes = (attrs) => omit(attrs, 'class', 'style');
const arrayToObject = (array) => array.reduce((p, c) => ((p[c] = true), p), {});
const vueClassToObject = (base) => Array.isArray(base)
    ? arrayToObject(base)
    : typeof base === 'object'
        ? Object.assign({}, base) : typeof base === 'string'
        ? { [base]: true }
        : {};
const vueStyleToObject = (base) => typeof base === 'object'
    ? Object.assign({}, base) : typeof base === 'string'
    ? Object.fromEntries(base
        .split(';')
        .filter(isTruthy)
        .map(x => x.split(':')))
    : {};
export function mergeClass(base, ...other) {
    if (!base || !other.some(isTruthy))
        return base;
    return other.reduce((p, c) => Object.assign(p, vueClassToObject(c)), vueClassToObject(base));
}
export function mergeStyle(base, ...other) {
    if (!base || !other.some(isTruthy))
        return base;
    return other.reduce((p, c) => Object.assign(p, vueStyleToObject(c)), vueStyleToObject(base));
}
export function defineFunctionalComponent(props, component, emits, displayName, inheritAttributes) {
    component.props = props;
    component.emits = emits;
    component.displayName = displayName;
    component.inheritAttrs = inheritAttributes;
    return component;
}
