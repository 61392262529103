import { defineStore } from 'pinia';
export const useModuleDialogsStore = defineStore({
    id: 'moduleDialogs',
    state: () => ({
        modules: [],
    }),
    actions: {
        addModule(moduleName, props) {
            let resolve;
            const promise = new Promise(p => {
                resolve = p;
            });
            this.modules.push({ moduleName, resolve: resolve, promise, props });
            return promise;
        },
        removeModule(moduleName) {
            const record = this.modules.find(x => x.moduleName === moduleName);
            record === null || record === void 0 ? void 0 : record.resolve();
            this.modules = this.modules.filter(x => x !== record);
        },
    },
});
