import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = { class: "v-input-control-inner" };
const _hoisted_2 = { class: "v-input-control-inner" };
const _hoisted_3 = ["for", "textContent"];
const _hoisted_4 = ["id", "type", "readonly", "disabled", "value"];
const _hoisted_5 = { class: "v-input__button-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["v-input", _ctx.mergeClass(_ctx.rootClasses, _ctx.$attrs.class)]),
        style: _normalizeStyle(_ctx.$attrs.style)
    }, [
        _createElementVNode("div", {
            class: "v-input-control",
            onClick: _cache[0] || (_cache[0] =
                //@ts-ignore
                (...args) => (_ctx.focus && _ctx.focus(...args)))
        }, [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    (!_ctx.noLabel)
                        ? (_openBlock(), _createElementBlock("label", {
                            key: 0,
                            class: _normalizeClass(["v-label", _ctx.labelClasses]),
                            for: _ctx.id,
                            textContent: _toDisplayString(_ctx.labelValue)
                        }, null, 10, _hoisted_3))
                        : _createCommentVNode("", true),
                    _createElementVNode("input", _mergeProps({
                        id: _ctx.id,
                        ref: "input",
                        type: _ctx.type,
                        readonly: _ctx.readonly,
                        disabled: _ctx.disabled
                    }, _ctx.attributes, { value: _ctx.valueWrapper }), null, 16, _hoisted_4)
                ]),
                _createElementVNode("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                            key: button.id
                        }, [
                            (!!button.transition)
                                ? (_openBlock(), _createBlock(_Transition, {
                                    key: 0,
                                    name: button.transition
                                }, {
                                    default: _withCtx(() => [
                                        _createElementVNode("i", _mergeProps({
                                            "aria-hidden": "true",
                                            class: "v-input__button"
                                        }, button.attributes), _toDisplayString(button.text), 17)
                                    ]),
                                    _: 2
                                }, 1032, ["name"]))
                                : (_openBlock(), _createElementBlock("i", _mergeProps({
                                    key: 1,
                                    "aria-hidden": "true",
                                    class: "v-input__button"
                                }, button.attributes), _toDisplayString(button.text), 17))
                        ], 64));
                    }), 128))
                ])
            ])
        ])
    ], 6));
}
