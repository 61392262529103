import { defineComponent, nextTick, reactive, ref, watch } from 'vue';
import { useDrag } from '../use/use-drag';
export default defineComponent({
    emits: ['click:ok', 'click:cancel', 'update:centralize', 'update:modelValue', 'hidden', 'shown'],
    props: {
        modelValue: { type: Boolean, required: true },
        caption: { type: String },
        centralize: { type: Boolean },
        width: { type: Number },
        height: { type: Number },
        draggable: { type: Boolean, default: true },
        topLeftCorner: { type: Object },
        footerButtons: { type: Array, default: () => [] },
        okButtonDisabled: { type: Boolean, default: false },
        okButtonText: { type: String, default: 'Ок' },
        cancelButtonText: { type: String, default: 'Отмена' },
    },
    setup(props, { emit }) {
        const visible = ref(false);
        const modalRef = ref();
        const rootClasses = reactive({
            'v-modal--shown': false,
        });
        const { left, top, drag } = useDrag(window.innerWidth * 0.2, window.innerHeight * 0.15);
        // const { activateFocusTrap, deactivateFocusTrap } = useFocusTrap();
        function toCenter() {
            if (!modalRef.value)
                return;
            left.value = (window.innerWidth - modalRef.value.clientWidth) * 0.5;
            top.value = (window.innerHeight - modalRef.value.clientHeight) * 0.5;
        }
        function show() {
            var _a, _b, _c, _d;
            left.value =
                (_b = (_a = props.topLeftCorner) === null || _a === void 0 ? void 0 : _a.left) !== null && _b !== void 0 ? _b : (!props.width ? window.innerWidth * 0.2 : (window.innerWidth - props.width) * 0.5);
            top.value =
                (_d = (_c = props.topLeftCorner) === null || _c === void 0 ? void 0 : _c.top) !== null && _d !== void 0 ? _d : (!props.height ? window.innerHeight * 0.25 : (window.innerHeight - props.height) * 0.5);
            visible.value = true;
            // nextTick(() => activateFocusTrap(modalRef.value));
            emit('update:modelValue', true);
            setTimeout(() => {
                var _a;
                rootClasses['v-modal--shown'] = true;
                emit('shown');
                const height = (_a = modalRef.value) === null || _a === void 0 ? void 0 : _a.clientHeight;
                if (height && height + top.value > window.innerHeight) {
                    top.value = Math.max(0, window.innerHeight - height - 8);
                }
                onCentralize();
            }, 250);
        }
        function onCentralize() {
            if (!props.centralize || !props.modelValue)
                return;
            nextTick(() => {
                toCenter();
                nextTick(toCenter);
                emit('update:centralize', false);
            });
        }
        function hide() {
            rootClasses['v-modal--shown'] = false;
            // deactivateFocusTrap();
            emit('update:modelValue', false);
            setTimeout(() => {
                visible.value = false;
                emit('hidden');
            }, 100);
        }
        function okClick() {
            hide();
            emit('click:ok');
        }
        function cancelClick() {
            hide();
            emit('click:cancel');
        }
        watch(() => props.centralize, onCentralize, { immediate: true });
        watch(() => props.topLeftCorner, (newValue, oldValue) => {
            var _a, _b;
            if ((newValue === null || newValue === void 0 ? void 0 : newValue.top) === (oldValue === null || oldValue === void 0 ? void 0 : oldValue.top) && (newValue === null || newValue === void 0 ? void 0 : newValue.left) === (oldValue === null || oldValue === void 0 ? void 0 : oldValue.left)) {
                return;
            }
            left.value = (_a = newValue === null || newValue === void 0 ? void 0 : newValue.left) !== null && _a !== void 0 ? _a : left.value;
            top.value = (_b = newValue === null || newValue === void 0 ? void 0 : newValue.top) !== null && _b !== void 0 ? _b : top.value;
        });
        watch(() => props.modelValue, (newValue, oldValue) => {
            if (newValue === oldValue)
                return;
            if (props.modelValue)
                show();
            else
                hide();
        }, { immediate: true });
        return {
            visible,
            modalRef,
            left,
            top,
            rootClasses,
            drag,
            okClick,
            cancelClick,
            mouseDownOnHeader: (e) => {
                if (!props.draggable)
                    return false;
                drag(modalRef.value, e);
                return false;
            },
        };
    },
});
