import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "v-list-viewer" };
const _hoisted_2 = { class: "v-list-viewer__data-table-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_loader = _resolveComponent("v-loader");
    const _component_v_paginated_data_table = _resolveComponent("v-paginated-data-table");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_v_loader, { key: 0 }))
                : (_openBlock(), _createBlock(_component_v_paginated_data_table, {
                    key: 1,
                    columns: _ctx.mutableColumns,
                    "onUpdate:columns": _cache[0] || (_cache[0] = ($event) => ((_ctx.mutableColumns) = $event)),
                    class: "v-list-viewer__data-table",
                    items: _ctx.items,
                    idField: _ctx.idField,
                    loading: _ctx.loading,
                    pageSize: _ctx.pageSize,
                    allowResize: "",
                    allowSort: "",
                    "onRow:dblclick": _cache[1] || (_cache[1] = entity => _ctx.goToEntityPage(entity.key))
                }, null, 8, ["columns", "items", "idField", "loading", "pageSize"]))
        ])
    ]));
}
